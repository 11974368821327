<template >
  <div>
    <Sobre />
  </div>
</template>

<script>
import Sobre from "../components/sobre/sobre.vue";
export default {
  components: {
    Sobre,
  },
};
</script>
<style scoped>
</style>