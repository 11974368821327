<template>
  <div>
    <TopMenu />
    <div style="padding-top: 20vh">

      <div class="container tracking-in-contract-bck text-center">
        <div class="row">
          <div class="col ">
            <h1 style="color: #f54308">
            A Empresa
            </h1>
          </div>
        </div>
      </div>

      <!-- Tópicos -->
      <div class="container mt-5 tracking-in-contract-bck text-center">
        <div class="col">
          <div class="col-md-12">
            <h4 style="color: #f54308">Unimos tecnologia com atendimento humanizado</h4>
          </div>
          <div class="col-md-12 mt-5">
            <p>
              A conexão com o cliente devedor articula em resultados potenciais com
              os nosso credores,em um mundo de comodits, ousamos usar a essência como diferencial.
            </p>
            <p>
              Tangibilizando o processo de cobrança em resultado satisfatório para os nosso clientes. A MG Cobrança
              possui em seu corporativo profissionais qualificados em cobrança humanizada
              e persuasiva, networking, métricas e engajamento são fórmulas integradas na gestão
              dessa empresa.
            </p>
          </div>
          <div class="container mt-5">
            <div class="row">
              <div class="col count_empresas">                   
                  <p>+ de</p><span><VueJsCounter start="0" end="150" duration="4000" thousand="." decimal=","></VueJsCounter></span>
                  <p>Empresas parceiras</p>   
                </div>
                
                <div class="col count_recuperados">      
                  <p>+ de R$</p><span><VueJsCounter start="5000000" end="10000000" duration="4000" thousand="." decimal=","></VueJsCounter></span>
                  <p>Em recuperação</p>                  
                </div>
            </div>            
          </div>

        </div>
      </div>
      <!-- Tópicos -->

    </div>


  </div>
</template>

<script>

import TopMenu from "../menu/topMenu.vue";
import VueJsCounter from 'vue-js-counter'

export default {
  name: "Sobre",
  data() {
    return {
     
    };
  },
  components: {
    TopMenu,
    VueJsCounter 
  },
  methods:{    
  }
};
</script>


<style scoped>
h1,h4{
  text-transform: uppercase;
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.div-row {
  display: flex;
  flex-direction: row;
}

.count_empresas{
  color: #f54308;
  font-weight: bold;
  font-size: 25px;
  border-radius: 50px;
}

.count_recuperados{
  color: #f54308;
  font-weight: bold;
  font-size: 25px;
  border-radius: 50px;
}

.count_empresas span,.count_recuperados span{
  font-size: 100px;
}

@media (max-width: 500px) {

.count_empresas span,.count_recuperados span{
  font-size: 60px;
}
}


@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>